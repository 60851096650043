
import { defineComponent } from 'vue';
import { v4 } from 'uuid';


type CTWFileSnippet = {
  id: string;
  name: string;
  content: string;
}
type CTWFileCategory = {
  name: string,
  id: string,
  color: string,
  snippets: CTWFileSnippet[],
};
type CTWFile = {
  categories: CTWFileCategory[];
};

type HomeViewData = {
  file: null | CTWFile;
  flash_text: string | null
};

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const LOCAL_STORAGE_KEY = 'ctw-file';

export default defineComponent({
  name: 'HomeView',
  components: {},
  data(): HomeViewData {
    return {
      file: null,
      flash_text: null
    }
  },
  mounted() {
    const loaded = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (loaded) {
      this.file = JSON.parse(loaded);
    }
  },

  methods: {
    save() {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.file));
    },
    clear() {
      if (confirm('Are you sure you want to clear the local storage?')) {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        this.file = null;
      }
    },
    addCategory() {
      if (!this.file)
        return;
      const newId = v4();
      this.file.categories.push({
        name: prompt('enter name') || 'New Entry',
        id: newId,
        color: getRandomColor(),
        snippets: []
      });
      this.$nextTick(() => {
        this.scrollToElementById(newId)
      });
      this.save();
    },
    saveFileToJson() {
      const json = JSON.stringify(this.file);
      const blob = new Blob([json], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'ctw-file' + new Date().toISOString() + '.json';
      a.click();
      URL.revokeObjectURL(url);
    },
    copyValueToClipboard(value: string) {
      console.log('value', value)
      const textarea = document.createElement('textarea');
      textarea.value = value;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      textarea.remove();
      this.flashText(value);
    },
    loadFileFromJson() {
      const input = document.createElement('input');
      input.type = 'file';
      input.onchange = (e) => {
        const nTarget = e.target as any;
        if (!nTarget.files)
          return;
        const file = nTarget.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          if (!e || !e.target)
            return;
          const json = e.target.result;
          this.file = JSON.parse(json ? json.toString() : '');
          this.save();
        };
        reader.readAsText(file);
      };
      input.click();
    },
    flashText(text: string) {
      this.flash_text = text;
      setTimeout(() => {
        this.flash_text = null;
      }, 500);
    },
    renameCategory(categoryId: string) {
      const category = this.file!.categories.find(c => c.id === categoryId);
      if (!category)
        return;
      const newName = prompt('enter new name');
      if (!newName)
        return;
      category.name = newName;
      this.save();
    },
    moveCategory(categoryId: string, direction: number) {
      const category = this.file!.categories.find(c => c.id === categoryId);
      if (!category)
        return;
      const index = this.file!.categories.indexOf(category);
      if (index === -1)
        return;
      const newIndex = index + direction;
      if (newIndex < 0 || newIndex >= this.file!.categories.length)
        return;
      this.file!.categories.splice(index, 1);
      this.file!.categories.splice(newIndex, 0, category);
      this.save();
    },
    moveSnippet(categoryId: string, snippetId: string, direction: number) {
      const category = this.file!.categories.find(c => c.id === categoryId);
      if (!category)
        return;
      const snippet = category.snippets.find(s => s.id === snippetId);
      if (!snippet)
        return;
      const index = category.snippets.indexOf(snippet);
      if (index === -1)
        return;
      const newIndex = index + direction;
      if (newIndex < 0 || newIndex >= category.snippets.length)
        return;
      category.snippets.splice(index, 1);
      category.snippets.splice(newIndex, 0, snippet);
      this.save();
    },
    deleteCategory(categoryId: string) {
      const category = this.file!.categories.find(c => c.id === categoryId);
      if (!category)
        return;
      if (!confirm('Are you sure you want to delete this category?'))
        return;
      const index = this.file!.categories.indexOf(category);
      if (index === -1)
        return;
      this.file!.categories.splice(index, 1);
      this.save();
    },
    deleteSnippet(categoryId: string, snippetId: string) {
      if (!confirm('Are you sure you want to delete this snippet?'))
        return;
      const category = this.file!.categories.find(c => c.id === categoryId);
      if (!category)
        return;
      const snippet = category.snippets.find(s => s.id === snippetId);
      if (!snippet)
        return;
      const index = category.snippets.indexOf(snippet);
      if (index === -1)
        return;
      category.snippets.splice(index, 1);
      this.save();
    },
    scrollToElementById(id: string, focus = false) {
      const element = document.getElementById(id);
      if (!element)
        return;
      element.scrollIntoView({ behavior: 'smooth' });
      if (focus)
        element.focus();
    },
    addSnippet(categoryId: string) {
      if (!this.file)
        return;

      const category = this.file.categories.find(c => c.id === categoryId);
      if (!category)
        return;

      const newId = v4();
      category.snippets.push({
        id: newId,
        name: prompt('enter name') || 'New Snippet',
        content: ''
      });

      //highlight input field for created snippet
      this.$nextTick(() => {
        this.scrollToElementById("textarea" + newId, true);
      });


      this.save();
    },
    createNew() {
      this.file = {
        categories: []
      },
        this.save();
    }
  }
});
